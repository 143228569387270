@import '~antd/dist/antd.css';
@import "./assets/style/animate.css";
@import "./assets/iconfont/iconfont.css";


/*公共样式--开始*/
html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Microsoft YaHei";
  font-size: 14px;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #232323;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
textarea {
  outline: none;
  border: none;
}

textarea {
  resize: none;
  overflow: auto;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}

.fl {
  float: left
}

.fr {
  float: right
}

.tl {
  text-align: left;
}

.tc {
  text-align: center
}

.tr {
  text-align: right;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;

}

/*美化webkit内核滚动条*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #777;
}

.pointer {
  cursor: pointer;
}

.ant-card {
  margin-bottom: 20px;
}

.ant-card button {
  margin-right: 10px;
}

p {
  margin: 0;
  padding: 0;
}

.displayFlex {
  display: flex;
}

.flex-start {
  justify-content: flex-start;

}

.space-between {
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}

.size28 {
  font-size: 28px;
}

.bold {
  font-weight: bold;
}

.ant-btn {
  border-radius: 6px;
  color: #4782FF;
  border-color: #4782FF;
}

.ant-btn-primary {
  color: #fff;
}

.ant-btn-link {
  border: none;
}

.ant-btn-text {
  border: none;
}