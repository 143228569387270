.zoomIn {
    animation: zoomIn 0.3s;
}

.zoomOut {
    animation: zoomOut 0.3s forwards;
}

@keyframes zoomIn {
    from {
        position: absolute;
        transform: scale(0);
    }

    to {
        position: relative;
        transform: scale(1);
    }
}


@keyframes zoomOut {
    from {
        position: absolute;
        transform: scale(1);
    }

    50% {
        opacity: 0;
    }

    to {
        position: absolute;
        opacity: 0;
        transform: scale(0);
    }
}