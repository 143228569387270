.login-body {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;

    .login-form {
        width: 50%;
        height: 100%;
        position: relative;

        .login-container {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);

            .system-title {
                display: flex;
                padding: 0 20px 20px;
                align-items: center;
                color: #464A53;

                .system-title-h1 {
                    font-size: 24px;
                }

                img {
                    width: 30px;
                    margin-right: 10px;
                }
            }

            .login-main {
                padding: 18px;
                width: 320px;
                box-sizing: border-box;
                box-shadow: 2px 4px 7px 0px rgba(119, 119, 119, 0.1);
                background-color: #fff;
                border-radius: 10px;

                input {
                    height: 44px;
                }


                .code-box {
                    display: flex;
                    justify-content: space-between;

                    .input {

                        color: #ABAFB3;
                        background-color: #F8FBFF;
                        vertical-align: middle;
                    }
                }
            }

            .login-submit {
                width: 100%;
                background: #4782FF;
                height: 56PX;
                box-shadow: -3px 6px 24px -9px rgba(71, 130, 255, 1);
                border-radius: 7px;
            }
        }

    }

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all .5s;
        background: url('../../assets/images/login-bg.png') right/contain no-repeat;
    }



    .iframe-box {
        overflow: hidden;
        width: 298px;
        height: 353px;
        margin: auto;
    }
}