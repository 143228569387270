.header {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
}

.collapsible-btn {
    padding: 0 16px;
}

.full-screen-btn {
    font-size: 20px;
    margin-right: 20px;
}

.header-right {
    display: flex;
    align-items: center;
}


.dropdown-title {
    padding: 0 10px;
}