body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ka-table {
  margin-top: 24px;

  .ant-table-container,
  .ant-table,
  th.ant-table-cell {
    background-color: transparent;
  }

  td.ant-table-cell {
    background-color: #fff;
    border-bottom: 10px solid #f0f2f5;
  }

  .ant-table-row {}

  .border-r {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: 80%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-color: #D4D4D4;
    }

    &:first-child::after {
      display: none;
    }

  }

  .more-operate {
    position: relative;
    left: 20px;
  }
}

.pagination {
  margin-top: 10px;
  padding: 10px 0;
  background-color: #fff;
  display: flex;

  justify-content: center;
}

.form-title {
  color: #464A53;
}

.video-course-card {
  display: flex;
  align-items: center;

  .pic {
    width: 100px;
    margin-right: 8px;

    flex-shrink: 0;

    img {
      width: 100%;
    }
  }
}

.search-title {
  padding: 30px 20px 0;
  background-color: #fff;
  color: #464A53;
}

.search-card {
  display: flex;
  padding: 20px 30px 0;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #fff;

  .search-content {
    width: 400px;
  }

  .operate-content {

    .operate-btn {
      text-align: right;

      button {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .search-sort {
      display: flex;
      margin-top: 20px;
      margin-bottom: 24px;
      justify-content: flex-end;
    }
  }
}

.ka-data-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.ka-data-card-text {
  flex-grow: 1;
  padding: 13px;
}

.ka-form-title {
  padding: 30px;
  background-color: #fff;
}

.ka-radio .ant-checkbox-inner {
  border-radius: 100%;
}

/* .lecturer-msg {
  font-size: 12px;
} */